module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[],"showInProduction":false,"axeOptions":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stamper - Program lojalnościowy z pieczątką na wynos","short_name":"Stamper - Program lojalnościowy z pieczątką na wynos","description":"Stamper to aplikacja, która zrzesza bardziej lojalnych klientów lubiących korzystać z kart lojalnościowych. Pozwala użytkownikom zbierać pieczątki z wielu programów w jednej aplikacji. Bez papierowych kart, zawsze pod ręką.","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","icons":[{"src":"icons/icon_512x512.png","sizes":"512x512"},{"src":"icons/icon_192x192.png","sizes":"192x192"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a0a86d274f36494b16761bd9ed9686bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 360px)","sm":"(max-width: 640px)","md":"(max-width: 768px)","lg":"(max-width: 1024px)","xl":"(max-width: 1280px)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
